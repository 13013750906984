<template>
  <el-dialog width="80%" :top="'5vh'" :visible.sync="modalOpen" :before-close="handleClose" :append-to-body="true"
    :destroy-on-close="true">
    <span slot="title"><i class="mdi mdi-cash-refund"></i> Comprobantes de Egreso</span>
    <el-table :data="tableData" v-loading="loadingTable" height="400" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <template>
            <el-table :data="props.row.detail" style="width: 100%" class="table_detail">
              <el-table-column prop="descripcion" label="Concepto">
              </el-table-column>
              <el-table-column prop="cantidad" label="Cantidad" width="180">
              </el-table-column>
              <el-table-column prop="costo" label="Valor">
                <template slot-scope="props">
                  ${{ formatNumber(props.row.costo) }}
                </template>
              </el-table-column>
              <el-table-column prop="descuento" label="Descuento">
                <template slot-scope="props">
                  ${{ formatNumber(props.row.descuento) }}
                </template>
              </el-table-column>
              <el-table-column prop="total_iva" label="Total IVA">
                <template slot-scope="props">
                  ${{ formatNumber(props.row.total_iva) }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Consecutivo" prop="consecutivo" width="100">
      </el-table-column>
      <el-table-column label="Fecha" prop="fecha" width="100"> </el-table-column>
      <el-table-column label="Vendedor" prop="seller.nombre" width="200"> </el-table-column>
      <el-table-column label="Proveedor" prop="person.nombre">
        <template slot-scope="props">
          <i class="el-icon-user"></i> {{ (props.row.person) ? props.row.person.nombre : '' }}
          <div class="observation_detail" v-show="props.row.observacion"><small><i class="el-icon-collection-tag"></i> {{ props.row.observacion }}</small></div>
        </template>
      </el-table-column>
      <el-table-column label="Subtotal" prop="subtotal" width="100">
        <template slot-scope="props">
          ${{ formatNumber(props.row.subtotal) }}
        </template>
      </el-table-column>
      <el-table-column label="Descuento" prop="discount1" width="100">
        <template slot-scope="props">
          ${{ formatNumber(props.row.discount1) }}
        </template>
      </el-table-column>
      <el-table-column label="Total IVA" prop="total_iva" width="100">
        <template slot-scope="props">
          ${{ formatNumber(props.row.total_iva) }}
        </template>
      </el-table-column>
      <el-table-column align="right" width="100">
        <template slot-scope="scope">
          <el-popconfirm confirm-button-text="Si" cancel-button-text="No, Cancelar" icon="el-icon-info" icon-color="red"
            title="Deseas eliminar este egreso?" @confirm="handleDelete(scope.$index, scope.row)">
            <el-button slot="reference" size="mini" type="danger">Eliminar</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="tableData.length > 0" class="pagination-box" style="text-align: end; padding-top: 10px">
      <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
        @current-change="handleCurrentPage"></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt
} from "@/utils/number";
import { getExpenses, deleteExpenses } from "@/api/document";
export default {
  name: "ListExpenses",
  props: ["modalOpen"],
  watch: {
    modalOpen: function (val) {
      if (val) {
        this.getExpensesData();
      }
    },
  },
  data() {
    return {
      loadingTable: false,
      type_id: 6, // comprobantes de egreso
      tableData: [],
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    getExpensesData() {
      this.loadingTable = true;
      getExpenses(this.pageSize, this.currentPage, this.type_id)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log(error);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getExpensesData();
    },
    handleDelete(index, row) {
      console.log('eliminando');
      this.loadingTable = true;
      deleteExpenses(row.id)
        .then(({ data }) => {
          if (data.code === 200) {
            this.getExpensesData();
            this.loadingTable = false;
          } else {
            this.$message.error("Error: " + data.error);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    closeModal() {
      this.$emit("close");
    },
    handleClose(done) {
      this.$emit("close");
    },
    formatNumber(number, round, aprox) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      if (typeof aprox != "undefined") {
        // APROXIMO AL ENTERO MAS CERCANO
        num = roundToTheNearestAnything(number);
      }
      return num;
    },
  },
};
</script>

<style lang="scss">
  .table_detail{
    padding-left: 50px;
  }
  .observation_detail{
    color: #bcbcbc;
    font-weight: 600;
  }
</style>