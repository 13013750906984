<template>
  <div>
    <el-dialog width="80%" height="500" top="50px" :visible.sync="modalSearchOpen" :before-close="modalSearchClose">
      <div slot="title">
        <i class="mdi mdi-file-search-outline"></i> Buscar Factura
      </div>
      <el-card class="card-shadow--small">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="Número de documento" size="mini" v-model="consecutive" clearable
              @clear="consecutive = null">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-date-picker v-model="date" type="date" size="mini" placeholder="Fecha" format="yyyy/MM/dd"
              value-format="yyyy-MM-dd" @clear="date = null">
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-select v-model="client" filterable remote clearable reserve-keyword size="mini"
              placeholder="Buscar Cliente" :remote-method="remoteMethod" :loading="loadingClient"
              @clear="client = null">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-option v-for="item in options" :key="item.id" :label="item.nombre" :value="item.id">
                <span style="float: left">{{ item.nombre }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"><i class="el-icon-postcard"></i> {{
                  item.documento
                }}</span>
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" size="mini" :loading="loadingFilter"
              @click="filterTable">Buscar</el-button>
          </el-col>
        </el-row>
      </el-card>
      <el-table :data="tableData" style="width: 100%" height="400" v-loading="loading"
        element-loading-text="Cargando Datos..." element-loading-spinner="el-icon-loading"
        element-loading-background="rgb(246 246 246 / 80%)">
        <el-table-column prop="consecutivo" label="Consecutivo" width="150">
          <template slot-scope="scope">
            <div style="font-size: 20px;"><strong>{{ scope.row.consecutivo }}</strong></div>
            <small>{{ scope.row.type.type_pivot.document_name }}</small>
          </template>
        </el-table-column>
        <el-table-column prop="fecha" label="Fecha" width="100"></el-table-column>
        <el-table-column prop="person.nombre" label="Cliente" min-width="200"></el-table-column>
        <!-- <el-table-column prop="branch.razon_social" label="Sucursal" width="200"></el-table-column> -->
        <el-table-column prop="total" label="Valor" width="120">
          <template slot-scope="scope">
            <div>$ {{ calculateTotal(scope.row) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="payment" label="Recibido" width="120">
          <template slot-scope="scope">
            <div>$ {{ calculatePay(scope.row.payment_detail) }}</div>
          </template>
        </el-table-column>
        <el-table-column align="right" fixed="right" label="Acciones" min-width="270" width="270">
          <template slot-scope="scope">
            <div v-if="scope.row.estatus != 3">
              <el-button size="mini" @click="handlePrint(scope.$index, scope.row)">
                <i class="el-icon-printer"></i> Imprimir
              </el-button>
              <el-button size="mini" type="primary" @click="handleView(scope.$index, scope.row)">
                <i class="el-icon-view"></i> Ver
              </el-button>
              <el-button size="mini" type="danger" @click="handleDeleteDocument(scope.$index, scope.row)">
                <i class="el-icon-delete"></i> Anular
              </el-button>
            </div>
            <div v-else>
              <el-popover placement="top-start" title="Razón de la anulación" width="200" trigger="hover"
                :content="scope.row.concepto_anulacion">
                <el-tag size="medium" :type='scope.row.status.color' slot="reference"><i class="el-icon-s-release"></i> Documento
                  Anulado</el-tag>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tableData.length > 0" class="pagination-box" style="text-align: end; padding-top: 10px">
        <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
          @current-change="handleCurrentPage"></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" icon="el-icon-close" @click="modalSearchClose">Cerrar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Print from "../../../views/apps/pos/ModalPrint.vue";
import PrintFormat from "../../../views/apps/pos/ModalPrintFormat.vue";
import { getUser } from "@/utils/auth";
import { getDocumentsToday, getDocumentById, anulateDocument } from "@/api/pos";
import { search as searchClient } from "@/api/person";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
export default {
  name: "ModalSearch",
  props: ["modalSearchOpen", "printerDefault"],
  watch: {
    modalSearchOpen(val) {
      if (val) {
        // if (this.tableData.length === 0) {
        this.getDocuments();
        // }
      }
    },
  },
  data() {
    return {
      loading: false,
      loadingFilter: false,
      modalPrintOpen: false,
      modalPrintFormatOpen: false,
      loadingClient: false,
      user: getUser(),
      document: {},
      detail: {},
      tableData: [],
      search: "",
      // datas
      data: {
        id: "",
        consecutive: "",
        branch: "",
        branch_nit: "",
        branch_address: "",
        client: "",
        client_name: "",
        client_address: "",
        client_nit: "",
        seller: "",
      },
      detailTableText: "",
      paymentTableText: "",
      detailData: [
        {
          description: "",
          quantity: 0,
          price: 0,
        },
      ],
      payments: [],
      payment: 0,
      subtotal: 0,
      total: 0,
      discount: 0,
      iva: 0,
      options: [],
      // Pagination
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      // Filters
      consecutive: null,
      date: null,
      client: null,
    };
  },
  methods: {
    handleDeleteDocument(index, row) {
      this.$prompt('Se anulará esta factura y se eliminará el detalle que contenga de forma permanente! No se podrá recuperar.', 'Atención', {
        type: 'warning ',
        confirmButtonText: 'Anular documento',
        cancelButtonText: 'Cancelar',
        inputErrorMessage: 'Debe escribir la razón de la anulación',
        inputPlaceholder: 'Porque se anulará?',
        confirmButtonClass: 'el-button--danger',
        inputValidator: (value) => {
          if (value !== '' && value !== null) {
            return true
          } else {
            return false
          }
        }
      }).then(({ value }) => {
        this.deleteDocument(value, row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Anulación cancelada'
        });
      });
    },
    deleteDocument(aulationText, row) {
      anulateDocument(aulationText, row.id)
        .then(({ data }) => {
          if (data.code === 200) {
            this.$message({
              type: 'success',
              message: 'Documento anulado exitosamente!'
            });
            this.filterTable()
            this.$store.commit("SET_DAILY_SALE", true);
          } else {
            this.$message.error("Oops", "Error: " + data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.loadingFilter = false;
          this.$message.error("Oops", "Error.");
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getDocuments();
    },
    filterTable() {
      this.loadingFilter = true;
      this.getDocuments();
    },
    calculateTotal(row) {
      var subtotal = formatInt(row.subtotal) + formatInt(row.total_iva)
      var total = subtotal - formatInt(row.descuento_valor)
      return formatNumber(roundToTheNearestAnything(total));
    },
    calculatePay(payment) {
      var pay = 0;
      payment.forEach((el) => {
        pay += formatInt(el.valor);
      });
      return formatNumber(pay);
    },
    modalSearchClose() {
      this.$emit("close");
      this.$store.commit("SET_ID_DOCUMENT", "");
    },
    getDocuments() {
      this.loading = true;
      this.$store.commit("SET_ID_DOCUMENT", "");
      if (
        this.consecutive != null &&
        this.date != null &&
        this.client != null
      ) {
        this.currentPage = 1;
      }
      getDocumentsToday(this.pageSize,
        this.currentPage,
        this.consecutive,
        this.date,
        this.client,
        this.user.branch.id)
        .then(({ data }) => {
          this.loading = false;
          this.loadingFilter = false;
          this.total_rows = data.total;
          this.tableData = data.data;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.loadingFilter = false;
          this.$message.error("Oops", "Error.");
        });
    },
    handlePrint(index, row) {
      this.$message({
        type: 'success',
        message: 'Imprimiendo comprobante'
      });
      this.$store.commit("SET_ID_DOCUMENT", row.id);
    },
    handleView(index, row) {
      this.loading = true;
      getDocumentById(row.id)
        .then(({ data }) => {
          this.loading = false;
          this.$store.commit("SET_BTN_NEW", true);
          this.$store.commit("SET_DOCUMENT_VIEW", data.document);
          this.$store.commit("SET_DETAIL_VIEW", data.detail);
          this.modalSearchClose();
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
          this.$message.error("Oops", "Error.");
        });
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      number = number == "" ? 0 : number;
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    findTable(data, search) {
      if (data.person !== null) {
        return (
          data.person.nombre.toLowerCase().includes(search.toLowerCase()) ||
          data.consecutivo
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
        );
      } else {
        return data.consecutivo
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loadingClient = true;
        searchClient(query, "cliente")
          .then(({ data }) => {
            this.loadingClient = false;
            this.options = data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.options = [];
      }
    },
  },
  components: {
    Print,
    PrintFormat,
  },
};
</script>

<style>
.el-popper{
  overflow-y: hidden;
}
</style>