<template>
  <div class="toolbar flex align-center justify-space-between">
    <div class="box-left box grow flex">
      <button
        @click="toggleSidebar"
        v-if="menuBurger !== 'right'"
        class="toggle-sidebar card-base card-shadow--small"
      >
        <i class="mdi mdi-menu"></i>
      </button>

      <img class="logo-mini" src="@/assets/images/logo.webp" alt="logo" />

      <search class="hidden-xs-only"></search>
    </div>
    <div class="box-right flex align-center pl-10">
      <el-tag type="danger" class="text_demo" v-if="demo"
        ><i class="el-icon-paperclip"></i> DEMO Infashion POS</el-tag
      >
      <span class="computer">
        <i class="mdi mdi-cash-register"></i> {{ dataPos.computer.name }} ({{
          dataPos.computer.code
        }})
      </span>

      <!-- <button class="fullscreen-button" @click="toggleFullscreen">
        <i class="mdi mdi-fullscreen" v-if="!fullscreen"></i>
        <i class="mdi mdi-fullscreen-exit" v-if="fullscreen"></i>
      </button> -->
      <!-- <el-button
        round
        icon="el-icon-s-data"
        @click="openDrawer"
        size="mini"
        style="margin-left: 16px"
      >
        Informe UPF
      </el-button> -->

      <el-tooltip
        class="item"
        effect="dark"
        :content="printerConnect ? 'Conectada' : 'Desconectada'"
        placement="bottom"
      >
        <button
          class="printer"
          :class="printerConnect ? 'on-print' : 'off-print'"
          @click="toogleConnectPrint"
        >
          <i class="mdi mdi-printer-off" v-if="!printerConnect"></i>
          <i class="mdi mdi-printer" v-if="printerConnect"></i>
        </button>
      </el-tooltip>

      <!-- NOTIFICATIONS -->
      <!-- <el-popover
        ref="popover"
        placement="bottom"
        :width="popoverWidth"
        trigger="click"
      >
        <notification-box
          :notifications="notificationData"
          :user="user"
          @quantity="setQuantity"
        ></notification-box>
      </el-popover>
      <el-badge :value="notificationsQuantity" class="notification-icon-badge">
        <el-button
          v-popover:popover
          icon="mdi mdi-bell"
          class="notification-icon"
        ></el-button>
      </el-badge> -->

      <span class="username">
        <router-link to="/profile">{{ user.name_user }}</router-link>
      </span>
      <el-dropdown trigger="click" @command="onCommand">
        <span class="el-dropdown-link">
          <img
            src="../assets/images/icono_2factura.svg"
            class="avatar"
            alt="avatar"
          />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/profile">
            <i class="mdi mdi-account mr-10"></i> Perfil
          </el-dropdown-item>
          <!-- <el-dropdown-item command="/calendar"><i class="mdi mdi-calendar mr-10"></i> Calendar</el-dropdown-item>
          <el-dropdown-item command="/contacts"><i class="mdi mdi-account-multiple mr-10"></i> Contacts</el-dropdown-item>-->
          <el-dropdown-item command="/logout" divided>
            <i class="mdi mdi-logout mr-10"></i> Cerrar sesion
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <button
        @click="toggleSidebar"
        v-if="menuBurger === 'right'"
        class="toggle-sidebar toggle-sidebar__right card-base card-shadow--small"
      >
        <i class="mdi mdi-menu"></i>
      </button>
    </div>
    <!-- DRAWER -->
    <drawer></drawer>
  </div>
</template>

<script>
import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

import NotificationBox from "@/components/NotificationBox";
import Drawer from "@/components/drawer/Drawer.vue";
import Search from "@/components/Search";
import { getUser, getToken, getDataPos, getDemo } from "@/utils/auth";
import { getIncomingTransfers } from "@/api/branch";
import { mapGetters } from "vuex";

export default {
  name: "Toolbar",
  props: ["menuBurger"],
  data() {
    return {
      baseUrl: process.env.VUE_APP_ROOT,
      popoverWidth: 300,
      fullscreen: false,
      lang: "us",
      user: getUser(),
      dataPos: getDataPos(),
      demo: getDemo(),
      notificationData: null,
      notificationsQuantity: null,
    };
  },
  computed: {
    ...mapGetters(["printerConnect"]),
  },
  methods: {
    openDrawer() {
      this.$store.commit("SET_DATA", {
        title: "Informe UPF",
        icon: "el-icon-info",
        component_active: "Upf",
      });
      this.$store.commit("SET_OPEN_DRAWER", true);
    },
    getTransfers() {
      let me = this;
      getIncomingTransfers({
        branch_id: this.user.branch.id,
        notifications: true,
      })
        .then(({ data }) => {
          me.notificationsQuantity = data.length > 0 ? data.length : null;
          me.notificationData = data;
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    setQuantity(quantity) {
      this.notificationsQuantity = null;
      if (quantity > 0) {
        this.notificationsQuantity = quantity;
      }
    },
    onCommand(path) {
      this.$router.push(path);
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    resizePopoverWidth() {
      if (window.innerWidth <= 1024) {
        this.popoverWidth = 250;
      } else {
        this.popoverWidth = 300;
      }
    },
    toggleFullscreen() {
      this.$fullscreen.toggle(document.querySelector("body"), {
        wrap: false,
        callback: () => {
          this.fullscreen = this.$fullscreen.getState();
        },
      });
    },
    toogleConnectPrint() {
      this.$message("Reconectando...");
      this.$store.commit("SET_PRINTER_RECONNECT", true);
    },
  },
  components: {
    NotificationBox,
    Search,
    Drawer,
  },
  mounted() {
    this.fullscreen = this.$fullscreen.getState();
    this.resizePopoverWidth();
    window.addEventListener("resize", this.resizePopoverWidth);
    // demo
    if (this.demo) {
      this.$notify.info({
        title: "Demo POS",
        message: "Estas en una version demo del sistema original.",
        showClose: false,
        position: "bottom-left",
        duration: 0,
      });
    }
    // events
    // window.Echo = new Echo({
    //   broadcaster: "pusher",
    //   key: process.env.VUE_APP_PUSHER_KEY,
    //   wsHost: 'localhost:8000',
    //   auth:{
    //     headers: {
    //       'Authorization': 'Bearer ' + getToken(),
    //       'Content-Type': 'application/json',
    //       'X-Requested-With': 'XMLHttpRequest'
    //     }
    //   },
    //   wsPort: 6001,
    //   disableStats: true,
    //   // encrypted: true,
    // });
    // window.Echo.channel("notifications").listen("Notifications", (e) => {
    //   console.log(e);
    // });
  },
  created() {
    this.getTransfers();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizePopoverWidth);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.toolbar {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .text_demo {
    margin-right: 20px;
  }

  .username {
    margin-left: 20px;
    font-weight: bold;
    @include text-bordered-shadow();

    a {
      color: $text-color;
      text-decoration: none;

      &:hover {
        color: $text-color-accent;
      }
    }
  }

  .avatar {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: 1px solid #fff;
    margin-left: 20px;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: 0px 3px 10px 0 rgba(49, 49, 93, 0.08),
        0px 2px 7px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .notification-icon {
    font-size: 20px;
    outline: none;
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 20px;
    //color: #aab7c5;
    color: transparentize($text-color, 0.7);
    @include text-bordered-shadow();

    &:hover {
      color: $text-color-accent;
    }
  }

  .toggle-sidebar {
    border: 1px solid transparent;
    height: 32px;
    min-height: 32px;
    line-height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
    outline: none;
    margin-right: 5px;
    font-size: 24px;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    color: $text-color;
    background: white;
    display: none;
    opacity: 0;
    transition: all 0.5s;

    &__right {
      margin-right: 0px;
      margin-left: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $text-color-accent;
      border-color: $text-color-accent;
    }
  }

  .fullscreen-button {
    font-size: 24px;
    cursor: pointer;
    outline: none;
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 20px;
    //color: #aab7c5;
    color: transparentize($text-color, 0.7);
    @include text-bordered-shadow();

    &:hover {
      color: $text-color-accent;
    }
  }

  .printer {
    font-size: 24px;
    cursor: pointer;
    outline: none;
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 20px;
    //color: #aab7c5;
    // color: transparentize($text-color, 0.7);
    // @include text-bordered-shadow();

    // &:hover {
    //   color: #c0c0c0;
    // }
  }

  .on-print {
    color: #11b95c;
  }

  .off-print {
    color: #d41d56;
  }

  .logo-mini {
    width: 32px;
    height: 32px;
    display: none;
  }

  .el-dropdown {
    .flag-icon {
      box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.08);
      cursor: pointer;
      border: 1px solid lighten($background-color, 20%);
      background-color: lighten($background-color, 20%);
    }
  }
}

@media (max-width: 650px) {
  .toolbar {
    .username {
      display: none;
    }
    .logo-mini {
      width: 90px;
    }
  }
}

@media (max-width: 1024px) {
  .toolbar {
    padding: 0 10px;

    .toggle-sidebar {
      display: block;
      opacity: 1;
    }

    .fullscreen-button {
      display: none;
    }

    .logo-mini {
      display: inherit;
      width: 90px;
    }
  }
}
</style>
