<template>
  <el-dialog
    title="Seleccione los prodcuctos de la lista"
    width="90%"
    :close-on-click-modal="false"
    :before-close="modalProductClose"
    :visible.sync="modalProductOpen"
    :top="'5vh'"
  >
    <el-row :gutter="10">
      <!-- <el-col :span="4">
        <el-input size="small" placeholder="Código empresa" v-model="codeBranch" clearable @keyup.native.enter="filterProduct"></el-input>
      </el-col> -->
      <el-col :span="4">
        <el-input size="small" placeholder="Código interno" v-model="code" clearable @keyup.native.enter="filterProduct"></el-input>
      </el-col>
      <el-col :span="5">
        <el-input size="small" placeholder="Digite el #Referencia" v-model="reference" clearable @keyup.native.enter="filterProduct"></el-input>
      </el-col>
      <el-col :span="7">
        <el-input size="small" placeholder="Digite la descripción" v-model="description" clearable @keyup.native.enter="filterProduct"></el-input>
      </el-col>
      <el-col :span="4">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          :loading="loadingFilter"
          @click="filterProduct"
        >Filtrar</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="row-table">
        <el-table
          style="width: 100%"
          height="400"
          ref="multipleTable"
          v-loading="loadingTable"
          element-loading-text="Cargando datos..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgb(246 246 246 / 80%)"
          :data="tableData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <!-- <el-table-column label="Código empresa" prop="product.codigo" width="130"></el-table-column> -->
          <el-table-column label="Código interno" prop="product.codigo_interno" width="130"></el-table-column>
          <el-table-column label="Descripcion" prop="product.descripcion"></el-table-column>
          <el-table-column label="Categoria" prop="product.category.descripcion"></el-table-column>
          <el-table-column label="Saldo" prop="saldo" width="80"></el-table-column>
          <el-table-column label="Precio sin IVA" width="130">
            <template slot-scope="props">
              <label class="price">$ {{formatNumber(props.row.product.precio_venta, true)}}</label>
            </template>
          </el-table-column>
          <el-table-column label="Precio con IVA" width="130">
            <template slot-scope="props">
              <label class="price">$ {{formatNumber(calculateSalePriceFinal(props.row.product), true)}}</label>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button icon="el-icon-close" @click="modalProductClose">Cancelar</el-button>
      <el-button type="success" icon="el-icon-plus" @click="addToDetail">Agregar a la lista</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
import { searchProduct } from "@/api/pos";
export default {
  name: "Product",
  props: ["modalProductOpen", "branch"],
  data() {
    return {
      loadingFilter: false,
      loadingTable: false,
      codeBranch: "",
      code: "",
      reference: "",
      description: "",
      tableData: [],
      multipleSelection: [],
    };
  },
  methods: {
    modalProductClose() {
      this.$emit("close");
    },
    filterProduct() {
      // code, reference, description, branch_id, transaction, type_id
      let me = this;
      this.loadingFilter = true;
      me.loadingTable = true;
      var data = {
        codeBranch: this.codeBranch,
        code: this.code,
        reference: this.reference,
        description: this.description,
        branch_id: this.branch.id,
        transaction: 2,
        type_id: 1,
      };
      if(this.codeBranch == '' && this.code == '' && this.reference == '' && this.description == ''){
        me.$message.warning("Atención, Debes ingresar un dato en los campos del filtro!");
        me.loadingFilter = false;
        me.loadingTable = false;
      }else{
      searchProduct(data)
        .then(({ data }) => {
          me.loadingFilter = false;
          me.loadingTable = false;
          if (data.code == 200) {
            this.tableData = data.data;
          } else {
            this.$message.error("Oops, Error.");
            console.log(data.data.error);
          }
        })
        .catch((error) => {
          me.loadingFilter = false;
          me.loadingTable = false;
          this.$message.error("Oops, Error al filtrar.");
          console.log(error);
        });
      }
    },
    addToDetail(){
      if (this.multipleSelection.length > 0) {
        this.$emit('addProducts', this.multipleSelection);
        this.modalProductClose()
      }else{
        this.$message('Selecciona algun producto');
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    calculateSalePriceFinal(product) {
      let tax = 1;
      if (product.iva_id == 1) {
        tax = parseFloat(product.tax.valor) / 100 + 1;
      }
      return Math.round(product.precio_venta * tax);
    },
  },
};
</script>
<style lang="css">
.row-table {
  margin-top: 20px;
}
</style>