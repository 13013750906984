<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <h2>Datos de registro</h2>
        </el-col>
        <el-col :span="24" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-button type="primary" class="btn-list" @click="modalOpen = true"
            ><i class="el-icon-s-grid"></i> Listar Egresos</el-button
          >
        </el-col>
      </el-row>

      <!-- FORMULARIO -->
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        status-icon
        label-width="90px"
        size="medium"
        class="form"
        :inline="true"
      >
        <el-row :gutter="50">
          <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
            <el-form-item label="Fecha" prop="date">
              <el-date-picker
                type="date"
                placeholder="Selecionar fecha"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd hh:mm:ss"
                v-model="form.date"
                autocomplete="off"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
            <el-form-item label="Vendedor" prop="seller_id">
              <el-select
                v-model="form.seller_id"
                filterable
                placeholder="Vendedor"
              >
                <el-option
                  v-for="item in sellers"
                  :key="item.seller.id"
                  :label="item.seller.nombre"
                  :value="item.seller.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
            <el-form-item label="Proveedor" prop="provider_id">
              <el-select
                v-model="form.provider_id"
                filterable
                placeholder="Proveedor"
              >
                <el-option
                  v-for="item in providers"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="12" :xl="12">
            <el-form-item label="Observación" prop="observation">
              <el-input
                type="text"
                v-model="form.observation"
                clearable
                autocomplete="off"
                placeholder="Ingresa una observación"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- DETALLE -->
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <detail-table></detail-table>
        </el-col>
      </el-row>

      <!-- BOTONES -->
      <el-row :gutter="50" style="margin-top: 50px">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-button type="success" @click="saveExpenses" :loading="loading"
            ><i class="el-icon-check"></i> Guardar</el-button
          >
        </el-col>
      </el-row>
    </div>
    <list :modalOpen="modalOpen" @close="closeModal"></list>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { saveExpenses } from "@/api/document";
import { getSeller, getByType } from "@/api/person";
import { getUser } from "@/utils/auth";
import { actualDate } from "@/utils/date";
import DetailTable from "./Detail.vue";
import List from "./List.vue";
export default {
  name: "Expenses",
  components: {
    DetailTable,
    List,
  },
  mounted() {
    this.getSellers();
    this.getProviders();
  },
  computed: {
    ...mapGetters(["expenses_detail"]),
  },
  data() {
    return {
      user: getUser(),
      loading: false,
      modalOpen: false,
      sellers: [],
      providers: [],
      form: {
        seller_id: 1,
        provider_id: null,
        date: actualDate(),
        observation: "",
      },
      rules: {
        seller_id: [
          {
            required: true,
            message: "Ingresa una código",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "Ingresa una fecha",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("expenses", ["resetDetail"]),
    getSellers() {
      getSeller(this.user.branch.id)
        .then(({ data }) => {
          this.sellers = data;
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    getProviders() {
      // 'vendedor' - 'proveedor' - 'cliente'
      getByType('proveedor')
        .then(({ data }) => {
          this.providers = data.data;
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    async saveExpenses() {
      var me = this;
      me.loading = true;
      me.$refs["form"].validate((valid) => {
        if (valid) {
          if (me.validateDetail()) {
            me.form.branch_id = me.user.sucursal_id;
            me.form.user_id = me.user.id;
            saveExpenses({
              document: me.form,
              detail: me.expenses_detail,
            })
              .then(({ data }) => {
                if (data.code === 200) {
                  me.resetForm("form");
                  me.$message({
                    message: "Registro Exitoso",
                    type: "success",
                  });
                } else {
                  me.$message.error("Error: " + data.error);
                  me.loading = false;
                }
              })
              .catch(function (error) {
                console.log("Err: ", error);
                me.loading = false;
                me.$message.error("Error! " + error.response.data.message);
              });
          } else {
            me.loading = false;
            return false;
          }
        } else {
          console.log("error submit!!");
          me.loading = false;
          return false;
        }
      });
    },
    validateDetail() {
      var send = true;
      this.expenses_detail.forEach((element) => {
        if (element.description === "") {
          this.showMessage("La descipción no puede ir vacia");
          send = false;
        } else {
          if (element.quantity === "" || element.quantity === 0) {
            this.showMessage(
              "La cantidad no puede ir vacio y debe ser mayor a cero"
            );
            send = false;
          } else {
            if (element.cost === "" || element.cost === 0) {
              this.showMessage("El valor no puede ir vacio");
              send = false;
            } else {
              // validar solo numeros
              var valoresAceptados = /^[0-9]+$/;
              if (element.cost.indexOf(".") === -1) {
                if (element.cost.match(valoresAceptados)) {
                  send = true;
                } else {
                  this.showMessage("El valor debe ser numérico");
                  send = false;
                }
              } else {
                this.showMessage("El valor debe ser numérico sin decimales");
                send = false;
              }
            }
          }
        }
      });
      return send;
    },
    showMessage(msd, typeMsn) {
      this.$message({
        message: msd,
        type: "warning",
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.loadingTable = false;
      this.resetDetail();
    },
    closeModal() {
      this.modalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form::v-deep {
  .el-form-item {
    width: 100%;
    .el-form-item__label {
      text-align: left;
    }
    .el-form-item__content {
      width: 100%;
    }
  }
}
.btn-list{
  float: right;
}
</style>