<template>
  <div>
    <el-table :data="tableData" v-loading="loadingTable" height="400" style="width: 100%" >
      <div slot="empty">
        <el-empty description="No hay datos"></el-empty>
      </div>
      <el-table-column prop="document.fecha" label="Fecha" width="120">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-calendar-clock"></i>
            {{ scope.row.document.fecha }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="document.consecutivo" label="Factura #" width="90">
        <template slot-scope="scope">
          <div>
            {{ scope.row.document.consecutivo }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="document.seller" label="Vendedor" width="120">
        <template slot-scope="scope">
          <div>
            {{ scope.row.document.seller.nombre }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="product.codigo" label="Código Empresa" width="160">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-barcode"></i>
            {{ (scope.row.product.codigo !== '') ? scope.row.product.codigo : '' }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="product.codigo_interno" label="Código Interno" width="160">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-barcode"></i>
            {{ (scope.row.product.codigo_interno !== '') ? scope.row.product.codigo_interno : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.descripcion" label="Producto">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-cart-outline"></i>
            {{ scope.row.product.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.category.descripcion" label="Categoria">
        <template slot-scope="scope">
          <div>
            <i class="el-icon-collection-tag"></i>
            {{ scope.row.product.category.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="document.payment_detail.descripcion" label="Formas de pago">
        <template slot-scope="scope">
          <div>
            <el-tag
            v-for="(payment, index) in scope.row.document.payment_detail"
            :key="index"
            :type="(payment.payment.id === 1) ? 'success' : 'danger'"
          >
            {{ payment.payment.descripcion }}
          </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cantidad" label="Cantidad" width="80">
        <template slot-scope="scope">
          <div>
            {{ scope.row.cantidad }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sale" label="Total venta" width="150">
        <template slot-scope="scope">
          <div>
            $ {{ calculateSale(scope.row) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
      style="text-align: end; padding-top: 10px">
      <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
        @current-change="handleCurrentPage"></el-pagination>
    </div>
  </div>
</template>
<script>
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
  formatFloat,
} from "@/utils/number";
import { getSales } from "@/api/reports/sales";
export default {
  name: "ListSales",
  props: ["get", "form"],
  watch: {
    get: function (val) {
      if (val) {
        this.getAll();
      }
    },
  },
  data() {
    return {
      loadingTable: false,
      tableData: [],
      //
      pageSize: 50,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    calculateSale(row) {
      let netSale = parseFloat(row.total_venta)
      let valueTax = parseFloat(row.iva)
      return formatNumber(roundToTheNearestAnything(netSale + (netSale * valueTax / 100)))
    },
    getAll() {
      let me = this
      this.loadingTable = true;
      var product_id =
        this.form.product_id != "" ? this.form.product_id : "null";
      var dateRange =
        this.form.dateRange != "" ? this.form.dateRange : "null";
      var paymentMethods =
        this.form.payment != "" ? this.form.payment : "null";
      getSales(this.pageSize, this.currentPage, product_id, dateRange, paymentMethods)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          this.$emit("complete", true);
          this.$emit("table-data", this.tableData);
        })
        .catch(function (error) {
          me.$emit("complete", true);
          console.log(error);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
  },
  created() {
    // this.getAll();
  },
};
</script>

<style lang="scss" scoped>
.btn-input {
  padding: 3px;
}
</style>