<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <modal-list-document :typeDocument="form.type_id" :transaction="1"></modal-list-document>

          <el-form :model="form" :rules="rules" ref="form" status-icon label-width="70px" size="medium" class="form"
            :inline="true" label-position="top">
            <el-form-item label="Cantidad" prop="quantity">
              <el-input-number v-model="form.quantity" :min="1" :max="100000"></el-input-number>
            </el-form-item>
            <el-form-item label="Código" prop="code">
              <el-input type="text" v-model="form.code" placeholder="Código Producto" clearable autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-form-item label="Número de Referencia" prop="reference_code">
              <el-input type="text" v-model="form.reference_code" placeholder="# Referencia" clearable autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Costo" prop="cost">
              <el-input type="number" v-model="form.cost" placeholder="$ 00.0" clearable autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Tercero" prop="third_id">
              <el-autocomplete v-model="person_search.nombre" :fetch-suggestions="querySearchAsync" ref="proveedor"
                placeholder="Buscar Tercero..." @select="handleSelect">
                <i slot="prefix" class="el-input__icon el-icon-s-custom"></i>
                <template slot-scope="{ item }">
                  <div class style="line-height: 1; padding: 8px 0 8px">
                    <i class="mdi mdi-account"></i>
                    {{ item.nombre }}
                    <br />
                    <small>
                      <i class="mdi mdi-clipboard-account"></i>
                      {{ item.documento }}
                    </small>
                  </div>
                </template>
                <template slot="append">
                  <el-tooltip class="item" effect="dark" content="Registrar Nuevo" placement="top">
                    <el-popover placement="bottom-start" ref="registerClient" trigger="click" @after-enter="focusField" v-model="visibleFormPerson">
                      <div class="content-data">
                        <div class="page-header header-accent-gradient card-base card-shadow--small header-client">
                          <h1>
                            <i class="mdi mdi-account-multiple-plus"></i> Registro
                            de Cliente
                            <el-tooltip class="item" effect="dark" content="Guardar Cliente" placement="top">
                              <el-button icon="mdi mdi-content-save-outline" circle class="btn-person-save"
                                @click="savePerson('formPerson')"></el-button>
                            </el-tooltip>
                          </h1>
                        </div>
                        <div class="form-box">
                          <el-form ref="formPerson" label-width="100px" size="medium" :model="personData" :rules="rules"
                            v-loading="loading">
                            <el-form-item label="Cédula / Nit" prop="nit">
                              <el-input ref="nit" placeholder="Cédula / Nit" v-model="personData.nit"></el-input>
                            </el-form-item>
                            <el-form-item label="Nombre" prop="name">
                              <el-input placeholder="Nombre" v-model="personData.name"></el-input>
                            </el-form-item>
                            <el-form-item label="Teléfono" prop="phone">
                              <el-input placeholder="Teléfono" v-model="personData.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="E-mail" prop="email">
                              <el-input placeholder="E-mail" v-model="personData.email"></el-input>
                            </el-form-item>
                            <el-form-item label="Dirección" prop="address">
                              <el-input placeholder="Dirección" v-model="personData.address"></el-input>
                            </el-form-item>
                            <el-form-item label="Ciudad" prop="city">
                              <el-input placeholder="Ciudad" v-model="personData.city"></el-input>
                            </el-form-item>
                            <el-form-item label="Cumpleaños">
                              <el-date-picker v-model="personData.birthday" type="date" placeholder="aaaa-mm-dd"
                                value-format="yyyy-MM-dd"></el-date-picker>
                            </el-form-item>
                          </el-form>
                        </div>
                      </div>
                      <el-button slot="reference" icon="mdi mdi-account-edit" style="font-size: 25px">
                        <small style="font-size: 13px">(F2)</small>
                      </el-button>
                    </el-popover>
                  </el-tooltip>
                </template></el-autocomplete>
            </el-form-item> -->
            <el-form-item label="Acciones" class="btn-actions">
              <el-button type="primary" @click="submitForm('form')" :loading="loading"
                icon="el-icon-plus">Agregar</el-button>
              <el-button type="success" icon="el-icon-check" @click="saveLoad">Guardar Ajuste</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <list :get="getData" @complete="complete"></list>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import ModalListDocument from '../ModalListDocument.vue';
import ModalWithoutProducts from '../ModalWithoutProducts.vue';
import { loadInventory, saveLoadInventory } from "@/api/document";
import { search as searchClient, save as savePerson } from "@/api/person";
import List from "./List.vue";
export default {
  name: "InputSetting",
  components: {
    List,
    ModalWithoutProducts,
    ModalListDocument,
  },
  created() {
    document.addEventListener("keydown", (e) => {
      if (e.key == "F2") {
        if (this.$refs.registerClient) {
          const elem = this.$refs.registerClient.$children[2].$el.click();
          this.$nextTick(() => this.$refs.nit.focus());
        }
      }
    });
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      getData: false,
      modalOpen: false,
      form: {
        code: "",
        quantity: 1,
        reference_code: "",
        third_id: "",
        cost: "",
        type_id: 7, // ajuste de entrada
      },
      rules: {
        code: [
          {
            required: true,
            message: "Ingresa una código",
            trigger: "blur",
          },
        ],
      },
      // autocomplete
      visibleFormPerson: false,
      person_search: {},
      personData: {
        id: "",
        nit: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        birthday: "",
      },
    };
  },
  methods: {
    saveLoad() {
      let me = this;
      this.$msgbox({
        title: "Atención",
        message:
          "Se ajustarán los datos en el inventario de la tienda. Desea continuar?",
        showCancelButton: true,
        confirmButtonText: "Si, ajustar entrada",
        cancelButtonText: "No, cancelar",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Cargando datos...";
            saveLoadInventory({ type_id: this.form.type_id })
              .then(({ data }) => {
                if (data.code === 200) {
                  me.getData = true;
                  me.resetForm("form");
                  me.$message({
                    message: "Se ha ajustado el inventario en la tienda",
                    type: "success",
                  });
                } else {
                  me.$message.error("Error: " + data.error);
                }
                me.loadingTable = false;
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(function (error) {
                instance.confirmButtonLoading = false;
                me.loadingTable = false;
                me.$message.error("Error! " + error.response.data.message);
                console.log("Err: ", error);
              });
          } else {
            done();
          }
        },
      });
    },
    submitForm() {
      let me = this;
      me.loading = true;
      loadInventory(me.form)
        .then(({ data }) => {
          if (data.code === 200) {
            me.getData = true;
            me.resetForm("form");
            me.$message({
              message: "Producto cargado!",
              type: "success",
            });
          } else {
            me.$message.error("Error: " + data.error);
          }
          me.loading = false;
          me.loadingTable = false;
        })
        .catch(function (error) {
          me.loading = false;
          me.loadingTable = false;
          me.$message.error("Error! " + error.response.data.message);
          console.log("Err: ", error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form.third_id = this.person_search.id
      this.loading = false;
      this.loadingTable = false;
    },
    complete(complete) {
      this.getData = false;
    },
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.length > 3) {
        searchClient(queryString, "proveedor")
          .then(({ data }) => {
            console.log(data.data);
            cb(data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleSelect(item) {
      this.personData = {
        id: item.id,
        nit: item.documento,
        name: item.nombre,
        email: item.email,
        phone: item.telefono,
        address: item.direccion,
        city: item.ciudad,
        birthday: item.nacimiento,
      };
      this.person_search = item
      this.form.third_id = item.id
    },
    focusField() {
      this.$nextTick(() => this.$refs.nit.focus());
    },
    savePerson(form) {
      this.loading = true;
      var data = {
        documento: this.personData.nit,
        nombre: this.personData.name,
        email: this.personData.email,
        telefono: this.personData.phone,
        direccion: this.personData.address,
        ciudad: this.personData.city,
        nacimiento: this.personData.birthday,
        proveedor: 1,
      };
      this.$refs[form].validate((valid) => {
        if (valid) {
          savePerson(data)
            .then(({ data }) => {
              this.loading = false;
              if (data.code === 200) {
                this.visibleFormPerson = false
                this.handleSelect(data.data)
                // this.personData.id = data.data.id;
                this.$message({
                  type: "success",
                  message: "Registro de clinete exitoso!",
                });
              } else {
                if (data.error.errorInfo[1] === 1062) {
                  this.$message.error(
                    "Error: El número de cedula o NIT ya existe. "
                  );
                  this.person_search.nombre = this.personData.nombre;
                  this.$nextTick(() => this.$refs.proveedor.focus());
                } else {
                  if (data.error.errorInfo[1] === 1048) {
                    this.$message.error(
                      "Error: Uno de los campos no puede ir vacio: " +
                        data.error.errorInfo[2]
                    );
                  } else {
                    this.$message.error(
                      "Oops, Error al registrar. " + data.error.errorInfo[2]
                    );
                  }
                }
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error("Oops, Error al registrar. ");
              console.log(error);
            });
        } else {
          this.loading = false;
          this.$message({
            message: "Atención!, Completa los campos obligatorios.",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.btn-actions {
  .el-form-item__label {
    color: transparent;
  }
}
</style>