<template>
  <el-dialog
    :visible.sync="modalPrintFormatOpen"
    :before-close="modalPrintClose"
    :width="'70%'"
    :close-on-click-modal="false"
    :top="'5vh'"
    :destroy-on-close="true"
  >
    <div slot="title">Documento # {{ data.consecutive }}</div>
    <!-- <vue-scroll class="scroller-print">
      <el-card class="box-card" style="font-size: 12px"> -->
        <div class="page-invoice scrollable">
          <div class="toolbar">
            <el-button class="animated fadeInRight" type="primary" plain size="small">
              <i class="mdi mdi-cart"></i> Check out
            </el-button>
            <el-button class="animated fadeInRight" plain size="small" @click="handleClick">
              <i class="mdi mdi-printer"></i> Print
            </el-button>
          </div>

          <div class="invoice bg-white black-text">
            <div class="invoice-top">
              <div class="logo">
                <img src="@/assets/images/logo.webp" alt="logo" />
              </div>
              <div class="info">
                <h2>John Wick</h2>
                <p>
                  jwick@email.com
                  <br />777-777-7777
                </p>
              </div>
              <!--End Info-->
              <div class="title">
                <h1>Invoice #1337</h1>
                <p>
                  Issued: May 23, 2018
                  <br />Payment Due: June 23, 2018
                </p>
              </div>
              <!--End Title-->
            </div>
            <!--End InvoiceTop-->

            <hr />

            <div class="invoice-middle">
              <div class="clientlogo">
                <img src="@/assets/images/avatar.jpg" alt="client logo" />
              </div>
              <div class="info">
                <h2>Client Name</h2>
                <p>
                  john.doe@email.com
                  <br />555-555-5555
                </p>
              </div>

              <div class="project">
                <h2>Project Description</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in diam sit amet felis ultricies ultricies vitae et tortor. Proin dapibus justo felis, ut imperdiet lacus accumsan quis.</p>
              </div>
            </div>
            <!--End Invoice Mid-->

            <div class="invoice-bottom">
              <table>
                <thead>
                  <tr>
                    <th>Item Description</th>
                    <th>Hours</th>
                    <th>Rate</th>
                    <th>Sub-total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Service 1</td>
                    <td>6</td>
                    <td>$55</td>
                    <td>$330.00</td>
                  </tr>

                  <tr>
                    <td>Service 2</td>
                    <td>3</td>
                    <td>$75</td>
                    <td>$225.00</td>
                  </tr>

                  <tr>
                    <td>Service 3</td>
                    <td>4</td>
                    <td>$75</td>
                    <td>$300.00</td>
                  </tr>

                  <tr>
                    <td>Service 4</td>
                    <td>250</td>
                    <td>$65</td>
                    <td>$16,250.00</td>
                  </tr>

                  <tr>
                    <td>Service 5</td>
                    <td>10</td>
                    <td>$70</td>
                    <td>$700.00</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>HST</td>
                    <td>13%</td>
                    <td>$2,314.65</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td class="Rate">
                      <h2>Total</h2>
                    </td>
                    <td class="payment">
                      <h2>$20,119.65</h2>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <div class="legalcopy flex mt-20">
                <p class="legal box grow mr-20 mt-0">
                  <strong>Thank you for your business!</strong>
                  Payment is expected within 31 days; please process this invoice within that time. There will be a 5% interest charge per month on late invoices.
                </p>
                <el-button type="primary" plain size="small" style="height:32px;">
                  <i class="mdi mdi-cart"></i> Check out
                </el-button>
              </div>
            </div>
            <!--End Invoice-->
          </div>
        </div>
      <!-- </el-card>
    </vue-scroll> -->
    <div slot="footer" class="dialog-footer">
      <el-dropdown split-button type="primary" @click="handleClick">
        <i class="el-icon-printer"></i> Imprimir
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-message">Factura Electronica</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-dialog>
</template>
<script>
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
export default {
  name: "Print",
  props: ["modalPrintFormatOpen", "document", "detail"],
  watch: {
    document: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.assignData(val);
          this.payments = val.payment_detail;
          this.payment = 0;
          if (val.payment_detail.length > 0) {
            val.payment_detail.forEach((el) => (this.payment += el.valor));
          }
        }
      },
    },
    detail: {
      deep: true,
      handler(val, oldVal) {
        this.detailData = [];
        if (val.length > 0) {
          this.assignDataDetail(val);
        }
      },
    },
  },
  data() {
    return {
      data: {
        id: "",
        consecutive: "",
        branch: "",
        branch_nit: "",
        branch_address: "",
        client: "",
        client_name: "",
        client_address: "",
        client_nit: "",
        seller: "",
      },
      detailData: [
        {
          description: "",
          quantity: 0,
          price: 0,
        },
      ],
      payments: [],
      payment: 0,
      subtotal: 0,
      total: 0,
      discount: 0,
      iva: 0,
    };
  },
  methods: {
    modalPrintClose() {
      this.$emit("close");
    },
    assignData(data) {
      this.data = {
        id: data.id,
        consecutive: data.consecutivo,
        branch: data.branch.razon_social,
        branch_nit: data.branch.nit,
        branch_address: data.branch.direccion,
        client: data.person.nombre,
        client_nit: data.person.documento,
        client_address: data.person.direccion,
        seller: data.seller.nombre,
        discount: data.descuento_valor,
      };
    },
    assignDataDetail(data) {
      let me = this;
      me.subtotal = 0;
      me.total = 0;
      me.discount = 0;
      me.iva = 0;
      data.forEach(function (el, ind) {
        me.subtotal += formatInt(el.precio) * formatInt(el.cantidad);
        me.discount += el.descuento;
        me.iva = el.iva;
        me.detailData.push({
          description: el.product.descripcion,
          quantity: el.cantidad,
          price: "$ " + formatNumber(formatInt(el.precio)),
        });
      });
      this.discount = this.discount + this.document.descuento_valor;
      this.iva = ((this.subtotal - this.discount) * me.iva) / 100;
      this.total = roundToTheNearestAnything(
        this.subtotal - this.discount + this.iva
      );
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      number = number == "" ? 0 : number;
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    handleClick() {
      window.print();
      console.log("print");
    },
  },
};
</script>
<style lang="css" scope>
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.center {
  text-align: center;
}
.divider {
  margin: 10px 0;
}
.table-details {
  font-size: 12px;
}
.text-totals {
  /* padding-left: 100px; */
}
.value-totals {
  float: right;
  padding-right: 10px;
}
.bold {
  font-weight: bold;
}
.footer-text {
  margin-top: 20px;
}
.scroller-print {
  padding: 10px;
  padding-top: 0px;
  width: 100%;
  height: 600px;
  box-sizing: border-box;
}
.return-client {
  padding-left: 10px;
}
</style>

<style lang="scss">
.page-invoice {
  .toolbar {
    max-width: 794px;
    margin: 0 auto;
    text-align: right;
    margin-bottom: 20px;
  }

  .invoice {
    background: white;
    width: 100%;
    max-width: 794px;
    min-height: 1123px;
    margin: 0 auto;
    padding: 2em;
    box-sizing: border-box;
    box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.3);

    hr {
      border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .invoice-top {
      overflow: hidden;
      margin-bottom: 30px;

      .logo {
        float: left;
        height: 60px;
        width: 60px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.07);
        text-align: center;

        img {
          height: 80%;
          margin-top: 10%;
        }
      }
      .info {
        display: block;
        float: left;
        margin-left: 20px;
        margin-top: 5px;

        & > * {
          margin: 0;
        }
      }
      .title {
        float: right;

        & > * {
          margin: 0;
        }

        p {
          text-align: right;
        }
      }
    }

    .invoice-middle {
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 30px;

      .clientlogo {
        float: left;
        height: 60px;
        width: 60px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        background: grey;

        img {
          width: 100%;
        }
      }

      .info {
        display: block;
        float: left;
        margin-left: 20px;
        margin-top: 5px;

        & > * {
          margin: 0;
        }
      }

      .project {
        margin-left: 52%;

        & > * {
          margin: 0;
        }
      }
    }

    .invoice-bottom {
      table {
        width: 100%;
        border-collapse: collapse;

        th {
          text-align: right;
          padding: 20px;
          padding-bottom: 5px;
          opacity: 0.7;

          &:first-child {
            text-align: left;
            padding-left: 0px;
            width: 100%;
          }
          &:last-child {
            padding-right: 0px;
          }
        }

        td {
          text-align: right;
          padding: 20px;
          padding-bottom: 5px;
          border-bottom: 1px solid #eee;

          &:first-child {
            text-align: left;
            padding-left: 0px;
            width: 100%;
          }
          &:last-child {
            padding-right: 0px;
          }
        }

        tfoot td {
          border: none;
          padding-top: 5px;
        }
      }
    }

    h1 {
      font-size: 1.5em;
      color: #222;
    }
    h2 {
      font-size: 0.9em;
    }
    h3 {
      font-size: 1.2em;
      font-weight: 300;
      line-height: 2em;
    }
    p {
      font-size: 0.7em;
      color: #666;
      line-height: 1.2em;
    }
  }
}
@media screen and (max-width: 768px) {
  .page-invoice {
    .invoice {
      .invoice-middle {
        .project {
          display: block;
          width: 100%;
          clear: both;
          padding: 10px 0;
          margin: 0;
        }
      }
      .invoice-bottom {
        table {
          th {
            padding: 5px;
          }

          td {
            padding: 5px;
          }
        }
      }
    }
  }
}

@media print {
  .layout-container .container .footer,
  .layout-container .container .header,
  .layout-container .container .horizontal-nav,
  .layout-container .footer,
  .layout-container .layout-picker,
  .layout-container .vertical-nav,
  .layout-container .header {
    display: none;
  }
  .page-ecommerce-products {
    .sidebar {
      .ps-container {
        display: none;
      }
    }
    .list{
      display: none;
    }
  }

    .invoice {
      display: block;
      box-shadow: none;
      max-width: 100%;
      padding: 0;
    }
  .page-invoice {
    .toolbar {
      display: none;
    }

    .invoice {
      box-shadow: none;
      max-width: 100%;
      padding: 0;
    }
  }
}
</style>