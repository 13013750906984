import request from '@/utils/request'

const module = 'category'
export function store(data) {
  return request({
    url: module +'/store',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: module +'/update',
    method: 'put',
    data
  })
}

export function deleteData(id) {
  return request({
    url: module +'/destroy/' + id,
    method: 'delete'
  });
}

export function getAll() {
  return request({
    url: module +'/getAll',
    method: 'get'
  });

}

export function getById(id) {
  return request({
    url: module +'/getById/' + id,
    method: 'get'
  });
}
