const defaultState = () => {
  return {
    detail: [{
      description: "",
      quantity: 1,
      cost: "",
      discount: "",
      total_iva: "",
    }],
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    SET_DETAIL_NEW_ROW: (state, connect) => {
      state.detail.push({
        description: "",
        quantity: 1,
        cost: "",
        discount: "",
        total_iva: "",
      });
    },
    RESET_DETAIL: (state, connect) => {
      state.detail = []
      state.detail.push({
        description: "",
        quantity: 1,
        cost: "",
        discount: "",
        total_iva: "",
      });
    },
    DELETE_ROW_DETAIL(state, index) {
      var i = state.detail.indexOf(state.detail[index]);
      i !== -1 && state.detail.splice(index, 1);
    },
  },
  actions: {
    addNewRowDetailAction({
      commit
    }, params) {
      commit('SET_DETAIL_NEW_ROW')
    },
    removeRow({
      commit
    }, index) {
      commit('DELETE_ROW_DETAIL', index)
    },
    resetDetail({
      commit
    }, params) {
      commit('RESET_DETAIL')
    }
  }
}
