<template>
	<div class="scrollable only-y">
		<div class="page-header">
			<h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }">
					<i class="mdi mdi-home-outline"></i>
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="card-base card-shadow--medium p-30">
			<el-row :gutter="50">
				<el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
					<h2>Datos de registro</h2>
					<el-form :model="form" ref="form" status-icon label-width="150px" size="medium" class="demo-form"
						:rules="rules" :label-position="innerWidth > 1300 ? 'left' : 'top'">
						<el-form-item label="Sucursal" prop="sucursal_id">
							<el-select v-model="form.sucursal_id" filterable value-key="id"
								placeholder="Seleccione la sucursal" clearable @change="getSellers">
								<el-option v-for="item in stores" :key="item.id" :label="item.razon_social"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="Requiere usuario anterior" prop="user_back">
				<el-switch
				  v-model="form.user_back"
				  active-text="Si"
				  inactive-text="No"
				>
				</el-switch>
			  </el-form-item>
			  <el-form-item label="Usuario" prop="user_id" v-if="form.user_back">
				<el-select
				  v-model="form.user_id"
				  filterable
				  value-key="id"
				  clearable
				  :placeholder="txtOldUsers"
				  :disabled="disabledOldUser"
				  @change="handleUser"
				>
				  <el-option
					v-for="item in oldUsers"
					:key="item.id"
					:label="item.nombre"
					:value="item.id"
				  ></el-option>
				</el-select>
			  </el-form-item> -->
						<el-form-item label="Nombre" prop="name">
							<el-input v-model="form.name" autocomplete="off" placeholder="Ingresa el Nombre"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="Email" prop="email">
							<el-input v-model="form.email" autocomplete="off" placeholder="Ingresa el Email"
								clearable></el-input>
						</el-form-item>
						<el-form-item label="Credenciales" prop="credentials">
							<el-select v-model="form.credentials" value-key="id" multiple placeholder="Seleccione">
								<el-option v-for="item in credentials" :key="item.id" :label="item.name"
									:value="item"></el-option>
							</el-select>

							<el-link icon="el-icon-edit" @click="changePassword = true" v-if="edit">Actualizar
								Contraseña</el-link>
						</el-form-item>

						<template v-if="!edit || changePassword">
							<el-form-item label="Contraseña" prop="password">
								<el-input type="password" v-model="form.password" autocomplete="off"
									placeholder="Ingrese la contraseña" clearable></el-input>
							</el-form-item>
							<el-form-item label="Confirmar Contraseña" prop="checkPass">
								<el-input type="password" v-model="form.checkPass" autocomplete="off"
									placeholder="Repita la contraseña" clearable></el-input>
							</el-form-item>
						</template>
						<!-- <el-form-item label="Vendedor asociado" prop="vendedor_id">
							<el-select v-model="form.vendedor_id" filterable value-key="id"
								placeholder="Seleccione un vendedor asociado" :disabled="disabledSellers" clearable>
								<el-option v-for="item in sellers" :key="item.id" :label="item.nombre"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item>
							<el-button type="primary" @click="submitForm('form')" :loading="loading" icon="el-icon-check"
								v-if="!edit">Guardar</el-button>
							<el-button type="warning" @click="submitForm('form')" :loading="loading" icon="el-icon-edit"
								v-else>Actualizar</el-button>
							<el-button @click="resetForm('form')" icon="el-icon-close">Cancelar</el-button>
						</el-form-item>
					</el-form>
				</el-col>
				<!-- grid -->
				<el-col :span="12" :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
					<el-table :data="
						tableData.filter(
							(data) =>
								!search ||
								data.name.toLowerCase().includes(search.toLowerCase())
						)
					" style="width: 100%; margin-top: 20px" height="400">
						<el-table-column label="Sucursal" prop="branch.razon_social" width="130"></el-table-column>
						<el-table-column label="Nombre" prop="name"></el-table-column>
						<el-table-column label="Email" prop="email"></el-table-column>
						<el-table-column align="right" width="200">
							<template slot="header" slot-scope="scope">
								<el-input v-model="search" size="mini" placeholder="Digite el nombre" />
							</template>
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" content="Editar" placement="top">
									<el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)"
										icon="el-icon-edit" circle></el-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
									<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
										icon="el-icon-delete" circle></el-button>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
  
<script>
import {
	store,
	update,
	deleteUser,
	getById,
	getUsers,
	getOldUsers,
	credentials,
} from "@/api/user";
import { getAll } from "@/api/store";
import { getSeller } from "@/api/person";
export default {
	name: "Register",
	components: {},
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("Porfavor ingrese la contraseña"));
			} else {
				if (this.form.checkPass !== "") {
					this.$refs.form.validateField("checkPass");
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("Porfavor repita la contraseña"));
			} else if (value !== this.form.password) {
				callback(new Error("Las contraseñas no coinciden!"));
			} else {
				callback();
			}
		};
		return {
			loading: false,
			edit: false,
			changePassword: false,
			disabledSellers: true,
			innerWidth: 0,
			labelPosition: "top",
			form: {
				id: "",
				user_id: "",
				sucursal_id: "",
				name: "",
				password: "",
				checkPass: "",
				email: "",
				credentials: [],
				user_back: true,
				// vendedor_id: null,
			},
			rules: {
				password: [{ validator: validatePass, trigger: "blur" }],
				checkPass: [{ validator: validatePass2, trigger: "blur" }],
				name: [
					{
						required: true,
						message: "Porfavor ingrese el nombre",
						trigger: "blur",
					},
				],
				user_id: [
					{
						required: true,
						message: "Porfavor seleccione un usuario anterior",
						trigger: "change",
					},
				],
				sucursal_id: [
					{
						required: true,
						message: "Porfavor seleccione la sucursal",
						trigger: "change",
					},
				],
				email: [
					{
						required: true,
						message: "Porfavor ingrese el email",
						trigger: "blur",
					},
					{
						type: "email",
						message: "Email invalido",
						trigger: ["blur", "change"],
					},
				],
				credentials: [
					{
						required: true,
						message: "Porfavor seleccione por lo menos una credencial",
						trigger: "change",
					},
				],
			},
			tableData: [],
			oldUsers: [],
			stores: [],
			credentials: [],
			sellers: [],
			search: "",
			txtOldUsers: "Seleccione usuario anterior",
			disabledOldUser: true,
		};
	},
	methods: {
		submitForm(formName) {
			this.loading = true;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.edit) {
						this.updateForm();
					} else {
						this.saveForm();
					}
				} else {
					this.loading = false;
					console.log("error submit!!");
					return false;
				}
			});
		},
		saveForm() {
			store(this.form)
				.then(({ data }) => {
					this.loading = false;
					if (data.code === 200) {
						this.getUsers();
						this.resetForm("form");
					} else {
						this.$message.error("Error! " + data.error.errorInfo[2]);
					}
				})
				.catch(function (error) {
					this.loading = false;
					console.log("Err: ", error);
				});
		},
		updateForm() {
			update(this.form)
				.then(({ data }) => {
					this.loading = false;
					if (data.code === 200) {
						this.getUsers();
						this.resetForm("form");
					} else {
						this.$message.error("Error! " + data.error.errorInfo[2]);
					}
				})
				.catch(function (error) {
					this.loading = false;
					console.log("Err: ", error);
				});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.loading = false;
			this.edit = false;
			this.changePassword = false;
			this.disabledSellers = true;
		},
		getOldUsers(data) {
			this.disabledOldUser = true;
			(this.txtOldUsers = "Cargando Datos..."),
				getOldUsers(data)
					.then(({ data }) => {
						this.disabledOldUser = false;
						(this.txtOldUsers = "Seleccione usuario anterior"),
							(this.oldUsers = data);
					})
					.catch(function (error) {
						this.disabledOldUser = false;
						console.log(error);
					});
		},
		getUsers() {
			getUsers()
				.then(({ data }) => {
					this.tableData = data;
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		getStores() {
			getAll()
				.then(({ data }) => {
					this.stores = data;
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		getSellers(seller) {
			let me = this
			me.sellers = []
			this.disabledSellers = true
			if(seller){
				this.disabledSellers = false
				let array = null;
				getSeller(seller)
					.then(({ data }) => {
						data.forEach((element) => me.sellers.push(element.seller));
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},
		getCredentials() {
			credentials()
				.then(({ data }) => {
					this.credentials = data;
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		handleUser(data) {
			var user = this.oldUsers.find((element) => element.id === data);
			this.form.name = user.nombre;
			this.form.email = user.email;
		},

		// table
		handleEdit(index, row) {
			let me = this;
			getById(row.id)
				.then(({ data }) => {
					this.edit = true;
					this.form = {
						id: data.id,
						user_id: "",
						sucursal_id: parseInt(data.sucursal_id),
						// vendedor_id: (data.vendedor_id) ? parseInt(data.vendedor_id) : null,
						user_back: (data.user_back == 0) ? false : true,
						name: data.name,
						email: data.email,
						credentials: data.roles,
					};
					// this.getOldUsers(data.sucursal_id);
					this.getSellers(data.sucursal_id);
					setTimeout(() => {
						me.form.user_id = data.usuario_id;
					}, 2000);
				})
				.catch(function (error) {
					console.log("Err: ", error);
				});
		},
		handleDelete(index, row) {
			deleteUser(row.id)
				.then(({ data }) => {
					this.loading = false;
					if (data.code === 200) {
						this.getUsers();
						this.resetForm("form");
					} else {
						this.$message.error("Error! " + data.error.errorInfo[2]);
					}
				})
				.catch(function (error) {
					this.loading = false;
					console.log("Err: ", error);
				});
		},
	},
	created() {
		this.getUsers();
		this.getStores();
		this.getCredentials();
	},
};
</script>
  
<style lang="scss">
.el-select {
	width: 100%;
}

.el-icon-circle-check {
	color: #1cc529;
}
</style>
  